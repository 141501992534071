/* Accordion */
		.accordion {
  			background-color: #e3e3e3 !important;
  			color: #444 !important;
  			cursor: pointer ;
  			padding: 18px;
  			width: 100% ;
  			text-align: left;
			display: inline-block;
  			vertical-align: middle;
  			line-height: normal;
  			border: none ;
  			outline: none ;
  			transition: 0.4s ;
		}

		.active, .accordion:hover {
  			background-color: #ccc !important;
		}

		.panel {
  			padding: 0 18px ;
  			background-color: white ;
  			max-height: 0 ;
  			overflow: hidden ;
  			transition: max-height 0.2s ease-out ;
		}
		.accordion:after {
  			content: '\02795'; /* Unicode character for "plus" sign (+) */
  			font-size: 13px;
  			color: #777;
  			float: right;
  			margin-left: 5px;
		}

		.active:after {
  			content: "\2796"; /* Unicode character for "minus" sign (-) */
		}
