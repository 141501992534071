img {
            border: 0;
        } 
img.lazy {
             opacity: 0; 
            } 
img.loaded, 
img.error {
        transition: opacity 1s;
			}
            img.initial,
			img.loaded,
			img.error {
				opacity: 1;
            } 
.loading{
    font-size: large!important;
}